@import '../variables.scss';

.pointer {
  cursor: pointer;
}

.cursor-scroll {
  // cursor: all-scroll;
}

.disabled {
  color: $color-dark-gray;
  pointer-events: none;
}

.w-1500 {
  width: 1500px !important;
}

.main {
  width: 100%;

  /*width: calc(100% - 240px);

  @media screen and (max-width: $break-ipadpro) {
    width: 100%;
  }*/
}

.radio-inline {
  align-items: center;

  input {
    width: auto;
  }

  span {
    width: 100%;
    padding-left: 10px;
  }
}

.input-with-unit {
  input {
    text-align: right;
  }

  span {
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
}

.input-with-unit-detail {
  input {
    text-align: left;
    width: auto;
  }

  span {
    margin-left: -70px;
    text-align: left;
    padding-left: 10px;
    flex: 1;
    align-items: center;
  }
}

.input-with-year {
  select {
    max-width: 100px;
    margin-right: 10px;
  }

  span {
    padding-left: 110px;
    width: 100%;
  }
}

.input-none-border {
  border: 0;
  pointer-events: none;
  padding: 0;
  height: auto !important;
  background-color: transparent !important;
}

.input-none-border + .mat-datepicker-toggle {
  display: none;
}

.border-light {
  border: 1px solid $color-light-gray;
}

.row-fix {
  margin: 0px;
}

.divine-horizontal {
  background-color: $color-black;
  width: 1px;
}

.border-b {
  border-bottom: 1px solid $border-content;
}

.alert {
  padding: 3px 5px;
  margin: 0;
}

.flex-1 {
  flex: 1;
}

.overflow-y {
  overflow-y: auto;
}

.xml-form {
  font-size: 12px;
  min-height: 450px;
}

.min-height {
  min-height: 140px;
}

p.text-warning-dialog {
  font-size: 15px;
}

//For Responsive//

.row .col-12.col-md-4,
.col-12.col-md-2 select,
.col-12.col-md-3 select {
  @media screen and (max-width: $break-ipad) {
    margin-bottom: 0.5rem;
  }
}

#vendorCollspaseVendor {
  .input-group {
    display: flex;
    align-items: center;
    .clear-input-icon {
      color: #0000008c;
      top: 50%;
      transform: translate(0%, -50%);
      &:hover {
        color: black;
      }
    }
  }
  button {
    background-color: white !important;
  }
  .input-none-border {
    background-color: #a6daf1 !important;
    margin: 0px 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-height: 30px;
    padding-left: 10px;
  }
}
.label-for-search {
  #vendorCollspaseVendor {
    .clear-input-icon,
    .button-group-search {
      opacity: 0;
      pointer-events: none;
    }
  }
}
.link {
  cursor: pointer;
  &:hover {
    color: $color-blue;
  }
}
