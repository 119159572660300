//Base Color//
$color-blue: #3268a5;
$color-strong-blue: #154e87;
$color-light-blue: #d1eafb;
$color-bright-blue: #0094d6;
$color-blue-sky: #00719e;

$color-black: #252525;
$color-dark-gray: #424242;
$color-gray: #a0a3a5;
$color-light-gray: rgb(216, 219, 221);

$color-light-purple: #e9ecff;
$color-purple: #cdd0fc;
$color-light-pink: #f2e4ff;
$color-pink: #e5cdfc;

$color-red: #d32620;
$color-light-red: #ff9490;
$color-green: #3b9314;
$color-greenlight: #00776d;

$color-yellow-light: #ffffe1;

$color-button-next: $color-blue;
$bg-topic-1: $color-light-purple;

//border Color//
$border-content: $color-gray;

//Table Color//
$tr-odd: #f2f2f2;
$table-collapseRow1: $color-light-pink;
$table-collapseRow2: #fff;
$table-collapseRow3: $color-light-purple;
$table-collapseRow4: $color-purple;
$table-collapseRow5: $color-pink;

//Gradient color//
$gradient-white-blue: linear-gradient(to bottom, #fff, $color-light-blue);
//$gradient-white-blue: linear-gradient(to bottom, rgba(255, 255, 255,0), $color-light-blue);
$gradient-BRblue-Sblue: linear-gradient(to bottom, $color-bright-blue, $color-blue);

//Layout Size//
$size-menu: 240px;
//$header-height: 117px;
$header-height: 130px;
$footer-height: 60px;

//Font Size//
$font-base: 13px;
$header-height: 130px;
$footer-height: 60px;

//Media Query//
$break-large: 1200px;
$break-ipadpro: 1024.98px;
$break-big: 991.98px;
$break-ipad: 768.98px;
$break-small: 575.98px;
$break-smallest: 450px;
