@import 'variables.scss';

button.btn-icon-white {
  background-color: #ffffff;
  margin-right: 5px;
  border: 1px solid #3daae5;
  box-shadow: none;
  min-width: 36px;
  min-height: 32px;
  padding: 0 3px;
  .material-icons {
    font-size: 25px;
    color: #0e88c8;
    display: inline;
    align-items: center;
    min-height: 29px;
  }
  i.material-icons {
    width: 23px;
  }
  span.text-after {
    color: #0e88c8;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }
  .material-icons + span {
    font-size: 13px;
    margin-left: 3px;
    color: #0e88c8;
  }
  &:hover {
    background-color: #0e88c8;
    .material-icons {
      color: #ffffff;
    }
    i.icon-custom-excel {
      //content: url('https://api.iconify.design/file-icons:microsoft-excel.svg?color=%23FFFFFF&height=23');
      content: url('./../assets/images/icon/excel-hover.png');
      width: 21px;
      height: 24px;
      padding-top: 3px;
    }
    .material-icons + span,
    span.text-after {
      color: white;
    }
  }
  &:active {
    outline: none;
  }
  &:focus {
    box-shadow: none;
  }
}

button.have-variant {
  border-color: $color-button-have-config;
  .mat-icon {
    color: $color-button-have-config;
  }
  background-color: /*#f6fef6*/ #ccffcc;
  /*border-width: 2px;*/
}

.back-btn {
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  display: flex;
  flex: 1;
  cursor: pointer;
  min-height: 30px;
  align-items: center;
  width: 70px;
  .mat-icon {
    font-size: 27px;
  }
  &:hover {
    color: $color-gray;
  }
}

button.btn-criteria {
  margin: 0;
  &.btn-active {
    background-color: #0e88c8;
    .material-icons {
      color: white;
    }
  }

}

button.btn-criteria-clear {
  margin: 0;
  background-color: white;
  color: #3daae5;
  //border-color: #d32620;
  position: relative;
  img {
    width: 16px;
    height: 16px;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  .material-icons {
    color: #3daae5;
  }
  &:hover {
    //background-color: #d32620;
    color: white;
    .material-icons {
      color: white;
    }
  }
}

.button-group {
  margin-bottom: 15px;
}

.box-btn-action-criteria {
  text-align: center;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  button {
    display: flex;
    align-items: center;
    mat-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

app-run-job-button {
  button.btn-icon-white span.text-after {
    margin-left: 2px;
  }
}

button.select-btn,
button.delete-btn {
  outline: none;
  font-size: 11px;
  margin: 0;
  padding: 3px 3px;
  cursor: pointer;
  .material-icons {
    font-size: 12px;
  }
  &:hover {
    outline: none;
  }
  &.disable {
    opacity: 0.3;
    pointer-events: none;
  }
}

button.select-btn {
  border: 1px solid #3daae5;
}

button.excel-btn {
  padding: 3.5px 4px;
  i {
    padding-left: 2px;
  }
}
