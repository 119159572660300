@import 'variables.scss';

/* Button */
.btn {
  background-image: none;
  font-size: 13px;
  margin: 0 5px;
  //margin-bottom: 5px;
  text-shadow: none !important;
  &:hover {
    opacity: .7;
  }
  @media screen and (max-width: $break-small) {
    font-size: 14px;
  }
}

.btn .material-icons {
  font-size: 18px ;
  line-height: 1.2;
  vertical-align: middle;
}
.btn-lg {
  width: 100%;
  min-width: 60px;

  .material-icons {
    font-size: 20px !important;
  }
}
.btn.btn-primary {
  color: #fff;
  background-color: $color-blue;
  border-color: $color-blue;

  &:hover {
    opacity: .7;
    background-color: $color-blue;
    border-color: $color-blue;
  }
}
.btn.btn-primary-border {
  color: #fff;
  border-color: $color-blue;

  &:hover {
    opacity: .7;
    border-color: $color-blue;
  }
}
.btn-secondary {
  color: #fff;
  background-color: $color-bright-blue;
  border-color: $color-bright-blue;

  &:hover {
    opacity: .7;
    background-color: $color-bright-blue;
    border-color: $color-bright-blue;
  }
}
.btn-default {
  color: $color-black;
  background-color: #fff;
  border-color: #ccc;
}
.btn.btn-success {
  color: #fff;
  background-color: $color-green;
  border-color: $color-green;

  &:hover {
    opacity: .7;
    background-color: $color-green;
    border-color: $color-green;
  }
}
.btn.btn-tree {
  color: #fff;
  background-color: $color-bright-blue;
  border-color: $color-bright-blue;

  &:hover {
    opacity: .7;
    background-color: $color-bright-blue;
    border-color: $color-bright-blue;
  }
}
.btn.btn-danger {
  color: #fff;
  background-color: $color-red;
  border-color: $color-red;

  &:hover {
    opacity: .7;
    background-color: $color-red;
    border-color: $color-red;
  }
}
.btn-close {
  height: unset !important;
  min-height: unset !important;
  padding: 2px !important;
}
.btn-light {
  border-radius: 7px !important;
  background: white;
  color: $color-black;
  border-color: #ccc;
  padding: 3px 15px !important;
  margin: 0px 10px !important;
  cursor: pointer !important;
  font-size: 16px;

  &:hover,
  &:focus {
    // background: rgb(241, 233, 233) !important;
    outline: none !important;
  }

  &:disabled,
  &:disabled:hover {
    color: #212529 !important;
    border: none;
    opacity: 0.6;
    background: #E0E1E3 !important;
    cursor: not-allowed !important;
  }

  @media screen and (max-width: $break-small) {
    margin: 0px 3px !important;
    font-size: 14px;
  }
}







