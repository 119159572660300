@import 'variables.scss';
/* sidebar*/
.sidebar {
  display: inline-flex;
  height: auto;
  min-height: 100%;
  border-right: 0px solid $color-light-gray;
  font-size: $font-base;
  background: #DAEEFC;
  .sidebar-btn-close {
    position: absolute;
    top: 0px;
    left: 240px;
    z-index: 100;
    cursor: pointer;
    button.btn {
      &:hover {
        opacity: 1;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
  a {
    color: $color-blue;
    font-size: 15px;
    font-weight: bold;

    &:hover,
    &.active {
      opacity: 0.5;
      text-decoration: none;
    }
  }
  .user-box {
    background: url('/../assets/images/img_headers/sidebar-bg.jpg');
    width: $size-menu;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    color: $color-black;
    font-size: 13px;
    padding: 12px 15px;
    border-bottom: 2px solid $color-light-blue;

    label {
      color: $color-blue;
      margin: 3px;
      font-weight: bold;
      font-size: 13px;
      font-family: 'SarabunRegular', 'ms Sans Serif' !important;
    }

    a {
      font-size: 12px;
    }

    span {
      font-size: 13px;
    }
  }
  .menu {
    color: #fff;
    background-image: $gradient-white-Lblue_bottom;
    position: relative;
    @media screen and (max-width: $break-ipadpro) {
      background-image: none;
    }

    .menu-content {
      opacity: 1;
      width: $size-menu;
    }

    .bg-main-blue-gradient {
      font-size: 14px;
      padding: 7px 0px;
      text-align: center;
          background-image: $gradient-BRblue-Sblue;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
      li {
        cursor: pointer;
        background-color:  $color-light-blue;
        //background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $color-light-blue);
        &:hover,
        &.active {
          background-color: #fff;
        }

        .item {
          padding: 10px 10px;

          &:hover,
          &.active {
            background-color: #fff;
          }
        }
      }
    }

    ul.sub-menu {
      margin-left: -30px;
    }

    .menu-list {
      font-size: 13px;

      .item {
        color: $color-blue;
        font-weight: bold;
        text-decoration: none;
        font-family: 'SarabunRegular', 'ms Sans Serif' !important;
        // box-shadow: 0 9px #96c5e4;
      }

      span {
        color: $color-black;
        font-size: 12px;
        font-family: 'SarabunLight', 'ms Sans Serif' !important;
      }

      hr {
        margin: 0px;
        border-bottom: 1px solid #fff;
      }

      ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        li {
          cursor: pointer;
          background-color:  $color-light-blue;
          //background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $color-light-blue);
          &:hover,
          &.active {
            background-color: #fff;
          }
  
          .item {
            padding: 10px 10px;
  
            &:hover,
            &.active {
              background-color: #fff;
              // box-shadow: 0 2px #96c5e4;
              transform: translateY(1px);
              // background-color: #fff;
            }
          }
        }
      }
    }

    .menu-footer {
      text-align: right;
      padding: 7px 10px 7px 10px;
      color: $color-bright-blue;
      font-weight: bold;
      font-size: 12px;
      span,
      a {
        color: $color-bright-blue;

        &:hover,
        &.active {
          opacity: 0.7;
          text-decoration: none;
        }
      }
    }
  }
  .content {
    width: 100%;
  }
  &.active-menu {
    position: relative;
    width: $size-menu;
    margin-left: 0px;
    transition:  all 1s ease;
  }
  &.inactive-menu {
    position: relative;
    margin-left: -($size-menu);
    transition: all 1s ease;
  }
  @media screen and (max-width: $break-ipadpro) {
    background-image: $gradient-white-Lblue_bottom;
    //&.active-menu {
    //  margin-left: -($size-menu);
    //}
    //&.inactive-menu {
    //  margin-left: 0px;
    //}
    //&.opened {
    //  display: block;
    //}
  }
}
@media print {
  #btn_1 {
    display: none;
  }
  @page {size: auto !important}
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: '';
  z-index: 10;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}
.headmenu {
  background: $color-bright-blue;
  color: #fff;
  padding: 10px;
}

.head-logo {
  background: url('/assets/images/img_headers/header_section.png');
  min-height: $header-height;
  width: $size-menu;
  background-repeat: no-repeat;
  // background-size: cover;
  background-size: 170px 90px;
  background-position: center;
  border-bottom: 1px solid $color-light-gray;
}

.mat-drawer-inner-container {
  background-color: #DAEEFC;
}