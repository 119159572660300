@import 'variables.scss';

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 100;
}

.custom-control-label::before,
.custom-control-label::after {
  top: 0.8rem;
  width: 1.25rem;
  height: 1.25rem;
}

.mat-elevation-z8 {
  box-shadow: none !important;
}

/* mat-toolbar */
.mat-toolbar.mat-primary {
  background: $color-blue;
  //background-image: $gradient-BRblue-Sblue;
  color: #fff;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 18px 'SarabunSemiBold', sans-serif;
  margin: 0;
}

/* Mat-card  */
.mat-card {
  box-shadow: none !important;
  padding: 0 10px !important;
  font-family: 'SarabunRegular', 'ms Sans Serif' !important;

  @media screen and (max-width: $break-ipad) {
    padding: 0 !important;
  }
}

.mat-card-title {
  margin: 0 !important;
  border-radius: 5px 5px 0px 0px;
  padding: 10px 20px;
  background-image: $gradient-blue-Bblue_bottom;
  color: #fff;
  font-weight: 700;
  font-size: 17px;
}

.mat-card-add {
  padding: 5px 15px !important;
}

/* mat-tab */
.mat-tab-group {
  font-family: 'SarabunSemiBold', sans-serif;
}

.mat-tab-label,
.mat-tab-link {
  font-family: 'SarabunSemiBold', sans-serif;
  font-size: 16px;
  max-height: 35px;

  &:hover {
    opacity: 1;
  }
}

.mat-tab-label.mat-tab-disabled {
  &:hover {
    opacity: 0.6;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $color-bright-blue;
}

.mat-tab-label-active {
  opacity: 1 !important;
  border-radius: 5px;
  background-color: $color-blue;

  .mat-tab-label-content {
    color: #fff;
  }
}

/* mat-dialog-container */
.mat-dialog-container {
  z-index: 2 !important;
  padding: 0 !important;
  max-height: 95vh !important;
  min-width: 300px;
  overflow: hidden !important;
  .mat-dialog-content {
    max-height: 85vh;
  }
}

.mat-dialog-title {
  margin: 0 !important;
  // padding: 15px 20px;
  // background-image: $gradient-blue-Bblue_bottom;
}

.mat-dialog-title h1 {
  font: 20px 'SarabunRegular', sans-serif;
  color: #fff;
  margin: 0 !important;
}

.mat-dialog-title button.close {
  padding: 5px 0;
  font-size: 20px;
  color: #fff;
  border: none;
  &:hover {
  }
  &:focus {
    outline: none;
  }
}

.mat-dialog-container .container-fluid {
  padding: 0 !important;
}

.mat-dialog-container .mat-card {
  padding: 10px 20px !important;

  @media screen and (max-width: $break-small) {
    padding: 5px 10px !important;
  }
}

.mat-dialog-content {
  margin: 0 0 10px !important;
  overflow-y: auto;
  // padding: 10px 20px !important;
  min-height: 180px;
  max-height: 85vh;
  @media screen and (max-width: $break-ipadpro) {
    min-height: 200px;
  }

  @media screen and (max-width: $break-ipad) {
    min-height: 350px;
  }

  @media screen and (max-width: $break-small) {
    padding: 5px 10px !important;
    min-height: auto;
  }
}

.mat-dialog-content .row {
  margin-bottom: 5px;
  align-items: flex-start;
}

.mat-dialog-content table {
  margin-bottom: 20px;
}

//Table in mat-dialog-//
.date_dialog {
  width: 900px;
  padding: 0 15px;
  height: auto;

  @media screen and (max-width: $break-large) {
    width: auto;
  }
}

/* sorting-dialog*/
.mat-dialog-content .sorting-dialog {
  width: 100%;
  margin-bottom: 20px;
  align-items: center;

  @media screen and (max-width: $break-ipad) {
    width: 100%;
  }
}

.sorting-box {
  overflow-x: auto;
  height: 350px;
  border: solid 1px #ccc;
  border-radius: 2px;
  overflow-y: scroll;
}

.sorting-box table {
  min-width: 300px;
  width: 100%;
}

.sorting-box table th {
  background-color: $color-bright-blue;
  color: #fff;
  padding: 8px 10px;
  font-size: 14px;
}

.sorting-box table td {
  padding: 5px 10px;
  font-size: 14px;
  border-bottom: solid 1px #ccc;
}

.sorting-btn-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .btn-sorting {
    padding: 0;
    width: 40px;
  }
  @media screen and (max-width: $break-ipad) {
    flex-direction: row;
    padding: 10px;
  }
}

.btn.btn-filter-list .material-icons {
  font-size: 24px !important;
}

.sorting-btn-group {
  text-align: center;
  padding: 20px 0;

  @media screen and (max-width: $break-ipad) {
    padding: 10px;
  }

  @media screen and (max-width: $break-smallest) {
    padding: 5px;
    display: flex;
  }
}

.sorting-dialog {
  .btn-sorting {
    width: 50px;
    display: flex;
    justify-content: center;
    background-color: $button-icon-bg;
    border: 1px solid $button-icon-border-color;
    color: $button-icon-border-color;
    margin-bottom: 5px;
    &:hover {
      background-color: $button-icon-border-color;
      border: 1px solid $button-icon-bg;
      color: $button-icon-bg;
    }
    @media screen and (max-width: $break-ipad) {
      width: 50px;
      transform: rotate(90deg);
    }
  }
  button.btn-icon-white {
    padding: 1px 2px;
    .material-icons {
      font-size: 18px;
      color: #0e88c8;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
  }
}

.btn.btn-sorting .material-icons {
  font-size: 30px !important;
}

.btn-sorting-double {
  margin-left: -20px;
}

/* mat-calendar */
.mat-calendar-body-selected {
  background-color: $color-bright-blue;
  color: #fff;
}

/* mat-paginator */
.mat-paginator {
  font-family: 'SarabunRegular', 'ms Sans Serif' !important;
  font-size: 14px !important;
}

/* mat-etc */
.mat-list-item {
  font-family: 'SarabunSemiBold', sans-serif;
}

.no-margin-content .mat-card-content {
  margin-bottom: 0px;
}

/* snack-bar */
.error-snackbar {
  background-color: #ff5252 !important;
  border-color: #ff5252 !important;
  .mat-simple-snackbar-action button {
    color: #ffffff;
  }
}

.success-snackbar {
  background-color: $color-success !important;
  border-color: $color-success !important;
  .mat-simple-snackbar-action button {
    color: #ffffff;
  }
}

.warning-snackbar {
  background-color: #e7a007 !important;
  border-color: #e7a007 !important;
  .mat-simple-snackbar-action button {
    color: #ffffff;
  }
}
