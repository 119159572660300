@import '../variables.scss';
@import '_reset.scss';
@import '_font.scss';
@import '_table.scss';
@import '_icon.scss';
@import '_typography.scss';
@import '_snackbar.scss';
@import '_global.scss';
@import 'all.scss';
@import '_sidebar.scss';
@import '_material.scss';
@import 'update_style.scss';
@import '_widget.scss';
@import '_button.scss';
@import '~font-awesome/css/font-awesome.css';


$fa-font-path: "~font-awesome/fonts";

html,
body {
  height: 100%;
  //overflow-y: hidden;
}
body {
  margin: 0;
  font-size: 16px;
  line-height: 1.4;
  font-family: 'SarabunRegular', 'ms Sans Serif' !important;
  background-color: #fff;
}


.main {
  width: 100%;
  &.sidebar-active {
    width: calc(100% - 240px);
  }
  @media screen and (max-width: $break-ipadpro) {
    width: 100%;
  }
  .body {
    margin-top: 10px;
  }
}


/* input */
.input-group {
  // height: 100%;
  select {
    flex: 1;
  }

  input {
    flex: 1;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }

  .input-group-append {
    button {
      padding: 0px 8px !important;
      height: 25px;

      img {
        display: flex;
      }
    }

    &.button-group-search {
      button {
        background: transparent;

        &:hover {
          background: #d6eaf8;
        }
      }
    }
  }

  input:disabled {
    max-width: calc(100% - 50px);
    border-bottom-right-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  input:disabled + .input-group-append {
    display: none;
  }
}

form {
  &.submited-form {
    input {
      &.ng-invalid,
      &.error-input {
        //border: 1px solid $color-red;
      }

      &.ng-valid {
        border: 1px solid initial;
      }
    }

    textarea {
      &.ng-invalid,
      &.error-input {
        //border: 1px solid $color-red;
      }

      &.ng-valid {
        border: 1px solid initial;
      }
    }

    select {
      &.ng-invalid,
      &.error-input {
        //border: 1px solid red;
      }

      &.ng-valid {
        border: 1px solid initial;
      }
    }

    input.ng-valid ~ .alert {
      display: none;
    }

    .alert {
      display: block;
      width: 100%;
    }

    span.required-icon {
      color: $color-red;
    }
  }

  &:not(.submited-form) {
    .alert {
      display: none;
    }

    span.required-icon {
      display: none;
    }
  }
}

.cdk-overlay-container {
  // position: absolute !important;
}

span.error-icon {
  color: $color-red;
}

.tooltip-box {
  cursor: pointer;
  position: relative;

  .tooltip-text {
    display: none;
    position: absolute;
    border: 1.5px dotted $color-bright-blue;
    padding: 3px 10px;
    width: 315px;
    right: 70px;
    top: 30px;
    background: white;
  }

  .tooltip-text-relative {
    position: relative;
    top: 0px;
    width: 430px;
    right: 0px;
  }

  p.sub-1 {
    padding-left: 15px;
  }

  p.sub-2 {
    padding-left: 45px;
  }

  &:hover .tooltip-text {
    display: block;
  }
}

/* button */
.col-button {
  margin: 10px 0px;
  width: 100%;
}

/* datepicker */
.mat-datepicker-toggle {
  color: $color-black;
  position: relative;

  .mat-icon-button {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    margin: 0px !important;
    background: transparent !important;

    &:focus,
    &:hover,
    &:active {
      background: transparent;
    }

    span.mat-button-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 25px;

      .mat-datepicker-toggle-default-icon {
        width: 25px;
      }
    }

    .mat-button-ripple-round {
      display: none;
    }
  }
}

/* modal */
mat-dialog-container {
  padding: 0px !important;
  color: white;

  .modal-footer {
    margin: 0 auto;
    justify-content: center !important;
  }
}

.cdk-global-scrollblock {
  top: 0px !important;
  position: static;
  overflow: hidden !important;
}

/* other */
.row {
  padding: 5px 0px;
}

.row-border {
  .row {
    border-bottom: 1px solid #f5f5f5;
    padding: 5px 0px;
  }
}

/* tab */
mat-tab-group {
  // border: 1px solid #CCD1D1; // change style tap group
  .mat-tab-labels {
    // background-color: #21618C; // change style tap group
    background-color: $color-light-blue; // change style tap group
  }

  .mat-tab-label {
    color: $color-black;
    height: auto;
    // padding: 4px 0px;
    padding: 10px; // change style tap group
    // background-color: #C0C1D6;
    background-color: transparent; // change style tap group
    min-width: 90px;

    &.mat-tab-label-active {
      background-color: white;
      opacity: 1;
    }

    &:hover {
      background-color: $color-bright-blue;
      color: $color-black;
    }
  }

  .mat-tab-body {
    padding: 10px;

    .mat-tab-body-content {
      overflow-x: hidden;
    }

    &.mat-tab-body-active {
      height: 100%;
    }
  }

  .mat-tab-label-content {
    font-size: 12px;
  }

  &.mat-tap-style-2 {
    border: none;

    .mat-tab-labels {
      background: transparent;
    }

    .mat-tab-label {
      // background-color: #f0f0d0;
      background-color: $color-light-blue; // change style tap group

      &:hover {
        background-color: $color-bright-blue;
        color: $color-black;
      }

      &.mat-tab-label-active {
        // background-color: #fdff9e;
        background-color: white;
      }
    }
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $color-bright-blue;
  //width: 90px !important;
}

/* pagination */
pagination-controls {
  .ngx-pagination {
    margin-top: 10px;
    text-align: center;
    font-size: $font-base;
    padding-left: 0px !important;

    li.pagination-previous {
      a:before,
      &:before {
        display: none !important;
      }
    }

    li.pagination-next {
      &:after,
      a:after {
        display: none !important;
      }
    }
  }
}

/* content */
.content-menu-top {
  position: relative;
  background-image: $gradient-BRblue-Sblue;
  border-bottom: 3px solid $color-strong-blue;
  color: #fff;
  min-height: 120px;

  .list-menu-manage {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
    position: absolute;
    top: 7px;
    right: 0px;
    background-color: #fff;
    padding-left: 5px;

    @media screen and (max-width: $break-ipad) {
      background-color: transparent;
    }

    a {
      text-decoration: none;
      color: $color-black;
      padding: 0px 10px;
      font-family: 'SarabunRegular';
      font-size: $font-base;

      &:hover {
        color: $color-blue;
      }

      @media screen and (max-width: $break-ipad) {
        color: #fff;

        &:hover {
          color: $color-bright-blue;
        }
      }
    }

    a.active {
      color: $color-red;
      //color: $color-bright-blue;

      &:hover {
        color: $color-red;
      }

      @media screen and (max-width: $break-ipad) {
        color: $color-light-red;
      }
    }

    @media screen and (max-width: $break-ipad) {
      position: relative;
      padding: 6px 0px 12px 0px;
    }
  }

  .topic {
    padding: 15px 10px;
    //position: absolute;
    //bottom: 15px;
    //left: 10px;

    h4 {
      line-height: 1.2;
      font-weight: bold;
      font-size: 20px;
      font-family: 'SarabunRegular', 'ms Sans Serif';
      //font-family: 'SarabunSemiBold';
    }

    span {
      font-size: 14px;
      font-family: 'SarabunLight';
    }

    @media screen and (max-width: $break-ipad) {
      position: relative;
      padding: 10px;
    }
  }
}

.content-box {
  // height: 100%;
  padding: 10px;
  //border: 1px solid $border-content;

  .content-detail {
    // width: 70%;
  }
}

.header-collapse {
  background-color: $color-light-purple;
  padding: 4px;
  display: flex;
  font-size: 14px;
  font-weight: bold;

  img {
    margin-left: 5px;
    height: 60%;
    transform: rotate(180deg);
  }

  &.open img {
    transform: rotate(0deg);
  }
}

.header-topic {
  background-color: #AADAFC;
  padding: 8px;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  // padding-bottom: 20px;
  img {
    margin-left: 5px;
    height: 60%;
    transform: rotate(180deg);
  }

  &.open img {
    transform: rotate(0deg);
  }
}

.collapse-detail {
  overflow-x: hidden;
  height: 0px;
  transition: height 0.5ms ease;

  &.show {
    transition: height 0.5ms ease;
    height: 100%;
  }
}

.content-border {
  border: 1px solid $color-light-gray;
  position: relative;
  padding: 20px 10px;
  width: 100%;
  margin-top: 20px;

  span.topic-header {
    font-family: 'SarabunRegular', 'ms Sans Serif';
    position: absolute;
    top: -16px;
    padding: 6px 8px;
    border-radius: 5px;
    background: $color-blue;
    color: #fff;
    font-size: $font-base;

    label {
      font-size: $font-base;
      color: #fff;
      margin-bottom: 0 !important;
    }
  }

  span.topic-header-none-color {
    font-family: 'SarabunRegular', 'ms Sans Serif';
    position: absolute;
    top: -16px;
    padding: 2px 8px;
    border-radius: 5px;
    // background: $color-blue;
    color: black;
    font-size: $font-base;

    label {
      font-size: $font-base;
      color: black;
      margin-bottom: 0 !important;
    }
  }
}

.content-border-search {
  position: relative;
  padding: 10px;
  width: 100%;
}

.box-explain {
  color: $color-black;
  font-size: $font-base;

  img {
    padding: 2px;
    margin: 0 4px;
  }

  .col-1 {
    flex: 0 0 12% !important;
    max-width: 12% !important;
  }

  .col-11 {
    flex: 0 0 88% !important;
    max-width: 88% !important;
  }
}

.select-searchType {
  color: $color-blue !important;
  text-decoration: underline;
  font-size: 0.8rem !important;
  width: 100%;
  max-width: 100%;
  padding: 0px !important;

  @media screen and (max-width: $break-large) {
    font-size: 0.7rem !important;
  }

  @media screen and (max-width: $break-big) {
    font-size: 0.7rem !important;
  }
}

/* dialog-result-save */
.mat-dialog-container {
  .header-dialog {
    padding: 10px 10px 0px 10px;
    //border-bottom: 1px solid $color-light-gray;
    background-image: $gradient-BRblue-Sblue;

    display: flex;
    align-items: baseline;
    position: relative;

    button.close {
      position: absolute;
      right: 2px;
      top: 2px;
      padding: 0 !important;
      color: #fff !important;

      &:hover {
        background-color: none !important;
      }
    }

    &.dialog-result-save {
      margin-bottom: 15px;
    }

    label {
      font-size: 16px;
      font-weight: 900;
      color: #fff !important;
    }
  }

  .content-dialog {
    overflow-x: hidden;
    padding: 10px 10px 5px 10px;

    table {
      text-align: center;

      input[type='checkbox'] {
        width: auto;
      }
    }
  }
}

.tab-button {
  .button {
    display: inline-block;
    padding: 2px 30px !important;
    border-radius: 10rem;
    color: #fff;
    text-transform: uppercase;
    font-size: $font-base;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: none;
  }

  &.next {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    .button {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-bright-blue;
        border-radius: 10rem;
        z-index: -2;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken($color-bright-blue, 15%);
        transition: all 0.3s;
        border-radius: 10rem;
        z-index: -1;
      }

      &:hover {
        color: #fff;

        &:before {
          width: 100%;
        }
      }
    }
  }

  .arrow {
    cursor: pointer;
    position: relative;
    display: none;
    width: 25px;
    height: 25px;
    z-index: 1;
    transition: all 0.2s linear;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 35%;
      height: 10%;
      top: 41%;
      left: 55%;
      background: $color-button-next;
      z-index: 2;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: all 0.2s linear;
    }

    &:after {
      z-index: 3;
      top: 59%;
      left: 55%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover {
      &:after,
      &:before {
        background: white;
      }
    }

    &:active {
      border: solid 8px white;

      &:after,
      &:before {
        background: white;
      }
    }
  }

  &.prev {
    .arrow {
      &:before,
      &:after {
        left: 35%;
      }

      &:after {
        transform: rotate(-140deg);
        top: 53%;
      }

      &:before {
        transform: rotate(-45deg);
        top: 33%;
      }
    }

    .button {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-bright-blue;
        border-radius: 10rem;
        z-index: -2;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken($color-bright-blue, 15%);
        transition: all 0.3s;
        border-radius: 10rem;
        z-index: -1;
      }

      &:hover {
        color: #fff;

        &:before {
          width: 100%;
        }
      }
    }
  }

  // .button {
  //   padding: 0px 5px !important;
  //   display: flex;
  //   align-items: center;
  //   position: relative;
  //   background: transparent;
  //   border: 1px solid $color-button-next;
  //   border-radius: 0px !important;
  //   color: white;
  //   transition: .2s;
  //   color: $color-button-next;
  //   &:hover {
  //     background: darken($color-button-next, 10%);
  //     color: white;
  //   }
  //   &:active,
  //   &:focus {
  //     background: darken($color-button-next, 30%);
  //     outline: none;
  //   }
  // }
}

.label-for-search {
  label {
    color: $color-black !important;
    text-decoration: none !important;
  }
}

//
.bg-topic-1 {
  label {
    margin: 5px;
    color: #fff;
  }
}

//fix button
.fix-color-button {
  button {
    background-color: transparent !important;
    color: $color-black !important;
    border: 1px solid $color-black !important;
  }

  button.bg-white {
    background-color: white !important;
    background-color: #F6FEFF;
    color: $color-black !important;
    border: 0px solid $color-black !important;
    text-align: center;
    height: 41px;
    width: 48px;
  }

  .tab-button.prev .button:before,
  .tab-button.prev .button:after,
  .tab-button.next .button:before,
  .tab-button.next .button:after {
    background-color: transparent !important;
  }

  button.mat-icon-button {
    border: none !important;
  }
}

button {
  background-color: transparent !important;
  color: $color-black !important;
  border: 1px solid $color-black;

  &:hover {
    background-color: $color-light-gray !important;
  }
}

.mat-calendar-body-cell-container {
  button.mat-calendar-body-cell {
    border: none;
  }
}


button.close,
button.mat-icon-button,
button.mat-calendar-period-button {
  border: none !important;
}

button.close {
  &:hover {
    background-color: transparent !important;
  }
}

.box-absolute {
  z-index: 1000;
  padding: 10px;
  border: 2px dashed $color-bright-blue;
  background: $color-light-blue;
  position: absolute;
  top: 15px;
  left: -60px;
  color: white;
  text-align: left;
  min-width: 470px;
}

span.clear-input-icon {
  position: absolute;
  right: 70px;
  color: transparent;
  z-index: 100000;
  top: 0;
  font-size: 1rem;
  cursor: pointer;
}

input:focus + span.clear-input-icon {
  color: $color-black;
}

.box-readonly-input {
  input {
    background-color: #e4e7ea !important;
    pointer-events: none;
  }

  label {
    color: $color-black !important;
  }
}

.date-hide-icon + .mat-datepicker-toggle .mat-icon-button {
  display: none;
}

.dialog-affiliation {
  // color: white;
  .box-alert {
    text-align: center;
    padding: 20px 0px 10px 0px;

    h3 {
      font-size: $font-base;
    }
  }

  .modal-footer {
    padding: 0px;

    button {
      border-radius: 7px !important;
      width: auto !important;
      font-size: 1rem !important;
      margin: 10px 0px !important;
    }
  }
}

.fix-col-for-search-page {
  .col-md-2 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

//Warning Box
.warning-box {
  color: $color-red;
  font-size: $font-base;
}

//Calender
.mat-calendar-body-selected {
  background-color: $color-blue !important;
}

.table-kb11 tr td input[type='text'] {
  width: 60%;
  margin: 0 auto;
}

.table-tk11 tr td input[type='text'] {
  width: 60%;
  margin: 0 auto;
}

// checkbox
.check-box-size {
  position: absolute;
  top: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  cursor: pointer;
}

.input-group-criteria {
  position: relative;
  flex: 1;
  input.form-control {
    max-width: 100%;
    width: 100%;
  }
  .icon-criteria {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0%, -50%);
    img {
      width: 23px;
    }
  }
  input:disabled + .icon-criteria {
    z-index: -10;
  }
}

// error page
.error-page {
  height: 100vh;
  background: #233142;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1 {
    //margin-top: -100px;
    margin-bottom: 20px;
    color: #facf5a;
    //text-align: center;
    font-size: 90px;
    font-weight: 800;
  }
  h2 {
    color: #98aec8;
    font-size: 30px;
    text-transform: uppercase;
  }
  h3 {
    color: #455d7a;
    //text-align: center;
    font-size: 30px;
    text-transform: uppercase;
  }
  .back-to-home {
    cursor: pointer;
    display: inline-block;
    padding: 12px 30px;
    background-color: #e3b539;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    transition: 0.2s all;
    font-size: 21px;
    margin-top: 50px;
  }
}
.wrapper-input-with-year {
  max-width: calc(100% - 50px);
}

mat-accordion{
  margin-top: 15px !important;
}

.example-action-buttons {
  padding-bottom: 20px;
}

.example-headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

.example-headers-align .mat-form-field + .mat-form-field {
  margin-left: 8px;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  margin-top: 12px !important;
}

.icon-add{
  margin-left: 12px;
  font-size: 30px;
  color: #18971e;
  cursor: pointer;
  margin-top: 0px;
}

.btn-right {
  text-align: right;
}


.icon-expand {
  margin-left: 12px;
  font-size: 24px;
  color: #3268a5;
  cursor: pointer;
  margin-top: 0px;
  border: 2px solid #3268a5;
  border-radius: 25px;
}

.count-question {
  margin-left: 17px;
  font-size: 13px;
  margin-top: 6px;
  margin-bottom: -20px;
}

.icon-edit-qs {
  margin-left: 12px;
  font-size: 24px;
  color: #ecd60f;
  cursor: pointer;
  margin-top: 0px;
}

.icon-delete-qs {
  margin-left: 12px;
  font-size: 24px;
  color: #d81919;
  cursor: pointer;
  margin-top: 0px;
  margin-right: 8px;
}