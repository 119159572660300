@import "../variables.scss";

html {
  height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 12px;
  margin: 0;
  font-family: 'SarabunRegular', "ms Sans Serif";
  overflow: auto;
}

app-route {
  position: absolute;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

p {
  margin: 0px;
  color: $color-black;
}

select {
  max-width: calc(100% - 50px);
  background-color: white;
  width: 100%;
  height: 30px !important;
  padding: 0px 6px !important;
  border-color: #dfdfdf;
  border-radius: 0px;
  font-size: 14px !important;

  option {
    font-size: 14px;
    font-family: 'SarabunRegular', "ms Sans Serif" !important;
  }

  option.select {
    background-color: #d1eafb;
  }

  &.disabled {
    padding: 0px !important;
  }
}

select[formControlName="pk"] {
  max-width: calc(100% - 73px);
}

select[formControlName="pk"]:disabled {
  max-width: calc(100% - 73px);
}

input[formControlName="sourceMoneyCode"]:disabled {
  max-width: calc(100% - 160px) !important;
}


input {
  max-width: calc(100% - 50px);
  padding: 0px 5px;
  width: 100%;
  height: 30px !important;
  font-family: 'SarabunLight', "ms Sans Serif" !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:read-only {
    background-color: #e4e7ea !important;
  }

  &.date-mat-input:read-only {
    background: transparent !important;
  }

  &.date-mat-input {
    padding-right: 20px;
  }


  &.date-mat-input-show-only {
    background-color: #e9ecef !important;

  }

  &.date-mat-input:disabled {
    background-color: #e4e7ea !important;
  }

  &.date-mat-input:disabled + .mat-datepicker-toggle {
    display: none;
  }
}

input.fix-width {
  max-width: calc(100% - 160px);
}

input.table-width {
  max-width: calc(100% - 10px);
}

span.clear-input {
  &:after {
    content: 'x';
  }
}

.form-control {
  padding: .375rem 5px;
  color: $color-black;

  &.input-none-border {
    padding: 0;
    height: auto !important;
    background-color: transparent !important;
  }

  &select {
    font-size: 15px;
  }

  &:focus {
    color: $color-black;
  }
  &.select-none-border {
    border: none;
    background: transparent;
    padding: 0 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

button {
  border-radius: 7px !important;
  background: white;
  color: $color-black;
  padding: 3px 15px !important;
  margin: 0px 10px !important;
  cursor: pointer !important;
  font-size: 12px;

  &:hover,
  &:focus {
    // background: rgb(241, 233, 233) !important;
    outline: none !important;
  }

  &:disabled,
  &:disabled:hover {
    color: #212529 !important;
    border: none;
    opacity: 0.6;
    background: #E0E1E3 !important;
    cursor: not-allowed !important;
  }
}

textarea {
  max-width: calc(100% - 50px);
  font-size: 14px !important;
}

textarea:focus,
input:focus,
select:focus {
  outline: none !important;
}

label {
  font-weight: bold;
  font-size: $font-base;
  color: $color-black;
  font-family: 'SarabunRegular', "ms Sans Serif" !important;
  //font-family: 'SarabunBold', "ms Sans Serif" !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.62);
}

.mat-tab-group,
.mat-tab-link {
  font-family: 'SarabunRegular', "ms Sans Serif" !important;
}

.mat-tab-label {
  font-family: 'SarabunSemiBold', "ms Sans Serif" !important;

  .mat-tab-label-content {
    font-size: $font-base;
  }
}

.btn-light {
  border-color: #dae0e5;
}


.dropdown {
  .dropdown-menu {
    p {
      cursor: pointer;
    }
  }
}

select[readonly] {
  pointer-events: none;
}

//edit align item flex of PK formcontrol
select[formControlName=pk].disabled {
  align-items: start;
}

.box-readonly-input {

  input,
  .date-mat-input:read-only {
    background-color: #e4e7ea !important;
    pointer-events: none;
  }

  .date-mat-input:disabled + .mat-datepicker-toggle {
    display: none;
  }

  label {
    color: $color-black !important;
  }
}


textarea,
input {
  color: $color-black;
}

.mat-dialog-title {
  margin: 0px !important;
  font: unset !important;
}

.mat-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
  max-height: initial !important;
}

.mat-dialog-content .topic-header {
  top: -10px !important;;
  padding: 4px 8px !important;;
}

.table-edit {
  input.form-control {
    display: inherit;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }
}
