@import "variables.scss";

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.text-blue {
  color: $color-blue;
}

.text-green {
  color: $color-green;
}

.text-blue-sky {
  color: $color-blue-sky;
}

.text-red {
  color: $color-red;
}

.text-black {
  color: $color-black;
}

.text-dark-grey {
  color: $color-dark-gray;
}

.text-grey {
  color: $color-gray;
}

.text-greenlight {
  font-weight: bold;
  color: $color-greenlight;
}

.text-underline-blue {
  font-weight: bold;
  text-decoration: underline;
  color: $color-blue;
}

.text-underline-red {
  font-weight: bold;
  text-decoration: underline;
  color: $color-red;
  position: relative;
  // set * after text set .text-underline-red
  // &:after {
  //   content: "*";
  //   position: absolute;
  //   right: -10px;
  // }
}

.text-underline-green {
  font-weight: bold;
  text-decoration: underline;
  color: $color-green;
}
