@font-face {
  font-family: 'SarabunBold';
  src: url('../assets/fonts/Sarabun-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'SarabunExtraBold';
  src: url('../assets/fonts/Sarabun-ExtraBold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'SarabunLight';
  src: url('../assets/fonts/Sarabun-Light.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'SarabunMedium';
  src: url('../assets/fonts/Sarabun-Medium.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'SarabunRegular';
  src: url('../assets/fonts/Sarabun-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'SarabunSemiBold';
  src: url('../assets/fonts/Sarabun-SemiBold.ttf');
  font-display: swap;
}
