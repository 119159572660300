.update_style {
  .head-logo {
    min-height: 70px !important;
    width: 130px !important;
    margin: 0 auto !important;
  }

  .header {
    height: 70px !important;
    .head-right__thai {
      font-size: 23px !important;
    }
    .head-right__eng {
      font-size: 19px !important;
    }
  }
  .menu-page {
    //height: calc(100vh - 140px) !important;
  }
  .wrapper {
    //min-height: 100%;
    //height: auto !important;
    //height: 100%;
  }
}

app-report-fm-data .after-box-action {
  margin-top: -40px;
  .mat-form-field-wrapper {
    max-width: 120px;
  }
}
