//Base Color/

$color-blue: #3268a5;
$color-strong-blue: #154e87;
$color-light-blue: #d1eafb;
$color-bright-blue: #0094d6;

$color-white-blue: #eff8ff;
$color-light-yellow: #ffffe5;
$color-orange: #ff9b1b;
$color-yellow: #fff843;
$color-black: #252525;
$color-dark-gray: #424242;
$color-gray: #a0a3a5;
$color-light-gray: rgb(229, 230, 231);

$color-light-Pblue: #d3ecff;

$color-red: #d32620;
$color-light-red: #ff9490;
$color-dark-red: #b42924;
$color-green: #3b9314;

$color-orange-light: #ffcb88;
$color-button-have-config: #52be80;

//border Color//
$border-light-panel: #e5e6e7;

//Gradient color//
$gradient-white-Lblue_bottom: linear-gradient(to bottom, #fff 50%, $color-light-blue 100%);
$gradient-blue-Bblue_bottom: linear-gradient(to bottom, $color-bright-blue 0%, $color-blue 90%);
$gradient-white-blue: linear-gradient(to bottom, #fff, $color-light-blue);
$gradient-BRblue-Sblue: linear-gradient(to bottom, $color-bright-blue, $color-blue);

//Layout Size//
//$size-menu: 360px;
$size-menu: 240px;
//$header-height: 117px;
$header-height: 115px;
$footer-height: 60px;

//Font Size//
$font-normal: 15px;
$font-base: 15px;
$font-header: 18px;
$footer-height: 60px;

//Media Query//
$break-large: 1200px;
$break-ipadpro: 1024.98px;
$break-big: 991.98px;
$break-ipad: 768.98px;
$break-small: 575.98px;
$break-smallest: 450px;

//table
$header-table-th-bg: #c7dbef;
$header-table-th-color: #213b64;
$header-group-tr-bg: #f1f8fd;
//$header-border-th-color: #d8e6ec;
$header-border-th-color: #cdd4d7;

//button icon
$button-icon-bg: white;
$button-icon-border-color: #213b64;

//input
$input-height: 38px;

$color-success: #28a745;
