table {
  font-size: $font-base;
  //word-wrap: anywhere;

  th.sort {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  input[type="checkbox"] {
    width: auto;
    margin: 0 auto;
  }

  td:last-child,
  th:last-child {
    padding-right: 5px !important;
  }

  th,
  td {
    padding: 5px 5px;
  }
}

table.table-scroll {
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
  // border: 1px solid $color-strong-green;
  color: $color-black;
  // text-align: center;

  th {
    padding: 7px 7px;
    // background-color: $color-primary;
    color: black;
    border: 1px solid white;
    text-align: center;
    border: 1px solid black;
  }

  tr:nth-child(odd) {
    background-color: $tr-odd;
  }

  td {
    padding: 7px 7px;
    font-size: 13px;
    border: 1px solid black;
    p.left {
      text-align: left;
    }
    p.right {
      text-align: right;
    }
    p.center {
      text-align: center;
    }
    span.icon {
      cursor: pointer;
      color: #DC0202;
    }
    span.icon-list {
      cursor: pointer;
      color: #006EB1;
    }
  }
}

table.table-normal-ong {
  // min-width: 991px;
  width: 100%;
  border: 1px solid $color-strong-blue;

  th {
    padding: 5px 5px;
    background-color: $color-blue;
    color: white;
    border: 1px solid white;
    text-align: center;
    font-weight: normal;
    border: 1px solid $color-strong-blue;
  }

  tr:nth-child(odd) {
    background-color: $tr-odd;
  }

  td {
    padding: 5px 5px;
  }

  tfoot {
    td {
      background-color: #F5F5F5;
    }

    td.summary {
      /*
      color: white;
      background-color: $color-blue;*/
      background-color: $color-light-purple;
    }

    td.summary-result {
      //color: $color-black;
      //background-color: $color-blue;
      background-color: $color-purple;
    }
  }
}

table.table-normal {
  // min-width: 991px;
  width: 100%;
  border: 1px solid $color-strong-blue;
  color: $color-black;

  th {
    padding: 5px 5px;
    background-color: $color-blue;
    color: white;
    border: 1px solid white;
    text-align: center;
    border: 1px solid $color-strong-blue;
  }

  tr:nth-child(odd) {
    background-color: $tr-odd;
  }

  td {
    padding: 5px 5px;
  }

  tfoot {
    td {
      background-color: #F5F5F5;
    }

    td.summary {
      //color: white;
      background-color: $color-light-purple;
    }

    td.summary-result {
      //color: $color-black;
      background-color: $color-purple;
    }
  }
}

table.table-result {
  width: 100%;
  text-align: center;

  th {
    background-color: $color-light-blue;
    color: $color-black;
    padding: 5px 0px;
  }

  tbody {
    td {
      background-color: white;
      color: $color-black;
      padding: 5px 0px;
    }
  }

  tfoot {
    td {
      background-color: #F5F5F5;
      padding: 5px 0px;
    }

    td.summary {
      text-align: right;
      color: white;
      background-color: $color-strong-blue;
      padding: 5px;
    }

    td.summary-result {
      text-align: right;
      color: $color-black;
      padding: 5px;
      background-color: $color-light-blue;
    }
  }
}

table.table-presave {
  border: 1px solid $color-light-gray;

  tr th {
    border: 1px solid $color-light-gray;
    color: $color-black;
    background-color: $color-light-purple;
  }
}

table.table-h-underline {
  th {
    text-decoration: underline;
  }
}

table.table-padding {
  tr {
    td {
      padding: 0px 5px;
    }
  }
}

table.table-search {
  tr {
    th:nth-child(3) {
      //text-align: left;
    }
  }
}

table.table-space {
  tbody {
    td {
      padding: 5px 9px;
    }
  }
}

table.table-search {
  th.pointer {
    text-decoration: underline;
    font-weight: bold;
  }
}

table.bg-light-blue {
  th {
    background-color: $color-light-blue;
    padding: 4px;
  }

  tfoot {
    td.summary {
      text-align: left;
      color: $color-black;
      background-color: $color-light-purple;
    }

    td.summary-result {
      text-align: right;
      background-color: $color-purple;
    }
  }
}

.table-set-tax {
  th {
  }

  td {
    padding: 10px 0px;
    background-color: white !important;
  }

  .radio-inline {
    justify-content: left;

    span {
      width: auto;
      padding-right: 15px;
    }
  }
}

.my-table-collpse {
  min-width: 991px;

  th {
    background-color: $color-blue;
    color: #fff;
  }

  th:nth-child(1) {
    text-align: center;
  }

  tbody tr td:nth-child(1) {
    padding-left: 10px;
  }

  tbody tr td:nth-child(2),
  th:nth-child(2) {
    text-align: right;
  }

  tbody tr:hover td {
    background: #E5E8E8;
  }

  tbody tr.tr-collapse:hover td {
    background: #F4F6F6;
  }

  tbody tr td:first-child {
    text-align: left;
    cursor: pointer;
  }

  tbody tr.first-row td {
    background: $table-collapseRow1;
  }

  tbody tr.second-row td,
  tbody tr.second-row td,
  tbody tr.second-row td {
    background: $table-collapseRow2;
  }

  tbody tr.third-row td {
    background: $table-collapseRow3;
  }

  tbody tr.forth-row td {
    background: $table-collapseRow4;
  }

  tbody tr.fifth-row td {
    background: $table-collapseRow5;
    //color: white;
  }

  tbody tr.tr-collapse td:nth-child(7) {
    text-align: left;
    padding-left: 10px;
  }

  th:nth-child(-n + 6),
  tbody tr td:nth-child(-n + 6) {
    border-right: 1.5px solid $color-light-gray;
  }

  th:nth-child(2),
  tbody tr td:nth-child(2) {
    padding-right: 10px;
  }

  tbody .tr-collapse {
    td {
      background-color: white !important;
    }
  }
}

.table-overflow-x {
  overflow-x: auto;
}

.table-responsive,
.table-responsive-sm,
.table-responsive-md,
.table-responsive-xl {

  border-collapse: collapse;
  overflow-x: auto;
  display: block;
  padding-bottom: 10px;
}

.table-overflow-x table {
  min-width: 540px;
}

.table-responsive table,
.table-responsive-xl table {
  max-width: 1500px;
  min-width: 1100px;
}

.table-responsive-md table {
  max-width: 1200px;
  min-width: 720px;
}

.table-responsive-sm table {
  max-width: 1200px;
  min-width: 540px;
}


span.break-word-text {
  word-break: break-word;
}

table.selected-tr {
  tr.choosed td {
    background-color: #e0dfdf;
  }
}

.description_table {
  margin-bottom: 3px;
}


.mat-dialog-content table {
  margin-bottom: 20px;
}
