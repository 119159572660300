@import "../variables.scss";

.max-h-400 {
  max-height: 400px
}

.max-h-80vh {
  max-height: 95vh;
}

.min-h-100 {
  min-height: 100px
}

.w-90 {
  width: 90%;
}

.bg-secondcolor {
  background-color: $color-light-blue;
}

.border-t-black {
  border-top: 1px solid #e6e6e6;
}

.bg-white {
  background-color: white;
}
.bg-menu-2 {
  background-color: $color-blue;
}

.bg-topic-1 {
  background-color: $color-blue;
}

tr.selected td {
  background-color: #cfd8dc !important;
}

.bg-yellow-light {
  background-color: $color-yellow-light;
}

.bg-light-blue {
  background-color: $color-light-blue;
}

.bg-content-search {
  background-color: $color-light-purple;
  margin: -5px;
  padding: 5px;
}

.link {
  cursor: pointer;

  &:hover {
    color: $color-blue;;
  }
}

select.disabled {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  border: none;
  background-color: transparent !important;
}
// .fix-color-button .bg-white {
//  background-color: white !important;
// }

@media screen and (max-width: 1650px) {
  .remove-padding-right-sm {
    padding-right: 0px;
  }
}
